<script>
import AppHomeCardWithChart from '@/components/AppHomeCardWithChart.vue';
import api from '@/services/api';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear.vue';
import AppHomeLongChart from '@/components/AppHomeLongChart.vue';
import { MERCHANT_ID } from '@/constants/names';
import VueApexCharts from 'vue-apexcharts';
import { formatNumber } from '@/util/helper';
import { formatToPrice } from '@/util/number';
import { statisticsFormatDate } from '@/util/date/calendar.util';
import CompStackedChart from '@/components/CompStackedChart.vue';
import { BButton } from 'bootstrap-vue';
import FlatPickr from 'vue-flatpickr-component';
import { checkLocales } from '@/util/lang';

export default {
    name: 'HomeV2',
    components: {
        BButton,
        CompStackedChart,
        AppHomeLongChart,
        ProgressLinear,
        AppHomeCardWithChart,
        apexchart: VueApexCharts,
        FlatPickr
    },
    data() {
        return {
            uniqueDatesSet: new Set(),
            paynetBalance: null,
            referralDateStart: null,
            referralDateEnd: null,
            referralTotal: null,
            defaultBody: {},
            defaultXaxis: [],
            subscribersCount: {
                series: [
                    {
                        name: 'SUBSCRIBERS',
                        data: []
                    }
                ],
                xaxis: [],
                labels: [],
                completed: false,
                chartBarColors: ['#1f42b4']
            },
            gifts: {
                series: [
                    {
                        name: this.$t('titles.gifts'),
                        data: []
                    }
                ],
                xaxis: [],
                completed: false,
                chartLineColors: ['#7367f0'],
                total: null
            },
            invoice: {
                series: [
                    {
                        name: 'Invoice',
                        data: []
                    }
                ],
                xaxis: [],
                completed: false,
                chartLineColors: ['#7367f0'],
                total: null
            },
            profitTotal: {
                series: [
                    {
                        name: 'Profit Total',
                        data: []
                    }
                ],
                xaxis: [],
                completed: false,
                chartLineColors: ['#7367f0'],
                total: null
            },
            profitExpenses: {
                series: [
                    {
                        name: 'Profit Expenses',
                        data: []
                    }
                ],
                xaxis: [],
                completed: false,
                chartLineColors: ['#7367f0'],
                total: null
            },
            profitRevenue: {
                series: [
                    {
                        name: 'Profit Revenue',
                        data: []
                    }
                ],
                xaxis: [],
                completed: false,
                chartLineColors: ['#7367f0'],
                total: null
            },
            verificationPieChart: {
                verified: null,
                unverified: null,
                completed: false,
                series: [],
                colors: [
                    '#7367f0',
                    '#a7204c',
                    '#f87002',
                    '#e17dab',
                    '#0097df',
                    '#739373',
                    '#b03966',
                    '#057f5f',
                    '#002296'
                ],
                labels: [],
                responsive: [
                    {
                        breakpoint: 576,
                        options: {
                            chart: {
                                width: 320
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }
                ],
                chart: {
                    width: 100,
                    type: 'donut',
                    toolbar: {
                        show: false
                    }
                },
                plotOptions: {
                    pie: {
                        expandOnClick: false
                    }
                },
                legend: {
                    show: false
                }
            },
            activeUsersPieChart: {
                active: null,
                inactive: null,
                completed: false,
                series: [],
                colors: [
                    '#7367f0',
                    '#a7204c',
                    '#f87002',
                    '#e17dab',
                    '#0097df',
                    '#739373',
                    '#b03966',
                    '#057f5f',
                    '#002296'
                ],
                labels: [],
                responsive: [
                    {
                        breakpoint: 576,
                        options: {
                            chart: {
                                width: 320
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }
                ],
                chart: {
                    width: 100,
                    type: 'donut',
                    toolbar: {
                        show: false
                    }
                },
                plotOptions: {
                    pie: {
                        expandOnClick: false
                    }
                },
                legend: {
                    show: false
                }
            },
            charges: {
                isFetched: false,
                daily_1: {
                    series: [
                        {
                            name: 'DAILY - 1',
                            data: []
                        }
                    ],
                    xaxis: [],
                    labels: [],
                    completed: false,
                    chartBarColors: ['#1f42b4']
                },
                daily_2: {
                    series: [
                        {
                            name: 'DAILY - 2',
                            data: []
                        }
                    ],
                    xaxis: [],
                    labels: [],
                    completed: false,
                    chartBarColors: ['#1f42b4']
                },
                daily_3: {
                    series: [
                        {
                            name: 'DAILY - 3',
                            data: []
                        }
                    ],
                    xaxis: [],
                    labels: [],
                    completed: false,
                    chartBarColors: ['#1f42b4']
                },
                premium_1: {
                    series: [
                        {
                            name: 'PREMIUM - 1',
                            data: []
                        }
                    ],
                    xaxis: [],
                    labels: [],
                    completed: false,
                    chartBarColors: ['#1f42b4']
                },
                premium_2: {
                    series: [
                        {
                            name: 'PREMIUM - 2',
                            data: []
                        }
                    ],
                    xaxis: [],
                    labels: [],
                    completed: false,
                    chartBarColors: ['#1f42b4']
                },
                premium_3: {
                    series: [
                        {
                            name: 'PREMIUM - 3',
                            data: []
                        }
                    ],
                    xaxis: [],
                    labels: [],
                    completed: false,
                    chartBarColors: ['#1f42b4']
                }
            },
            invitationStatistics: {
                series: [],
                categories: [],
                isPending: true
            },
            subscriptionEventStatistics: {
                isPending: true,
                categories: [],
                activationSeries: [],
                deactivationSeries: []
            },
            registration: {
                isLoaded: false,
                series: [],
                categories: []
            },
            newUserUssd: {
                isLoaded: false,
                series: [],
                categories: []
            },
            date_start: null,
            date_end: null,
            first_day: null,
            datesForCategories: []
        };
    },

    computed: {
        MERCHANT_ID() {
            return MERCHANT_ID;
        },
        subscriber() {
            if (parseInt(MERCHANT_ID) === 1) {
                return {
                    series: [
                        {
                            name: 'APP',
                            data: []
                        },
                        {
                            name: 'TELEGRAM',
                            data: []
                        },
                        {
                            name: 'USSD',
                            data: []
                        }
                    ],
                    xaxis: [],
                    completed: false,
                    chartLineColors: ['#00e296', '#2AABEE', '#ff9f43'],
                    total: null
                };
            } else {
                return {
                    series: [
                        {
                            name: 'APP',
                            data: []
                        },
                        {
                            name: 'TELEGRAM',
                            data: []
                        },
                        {
                            name: 'USSD',
                            data: []
                        }
                    ],
                    xaxis: [],
                    completed: false,
                    chartLineColors: ['#00e296', '#2AABEE', '#ff9f43'],
                    total: null
                };
            }
        }
    },

    async mounted() {
        await this.fixStatisticsDates();
    },

    methods: {
        checkLocales,
        formatToPrice,
        formatNumber,
        statisticsFormatDate,

        getAllDatesInRange(startDate, endDate) {
            const dates = [];
            let currentDate = new Date(startDate);

            // Loop from the start date to the end date
            while (currentDate <= endDate) {
                dates.push(this.statisticsFormatDate(currentDate).substring(5));
                currentDate.setDate(currentDate.getDate() + 1);
            }

            return dates;
        },

        async fixStatisticsDates() {
            const date = new Date();
            const year = date.getUTCFullYear();
            // const beforeMonth = String(date.getMonth()).padStart(2, '0');
            const currentMonth = String(date.getMonth() + 1).padStart(2, '0');
            // const day = String(date.getDate()).padStart(2, '0');

            const date_start = new Date();
            const date_end = new Date();
            date_start.setDate(date_end.getDate() - 30);

            this.referralDateStart = this.statisticsFormatDate(date_start);
            this.referralDateEnd = this.statisticsFormatDate(date_end);
            this.date_start = this.statisticsFormatDate(date_start);
            this.date_end = this.statisticsFormatDate(date_end);
            this.first_day = `${year}-${currentMonth}-01`;

            this.datesArr = this.getAllDatesInRange(date_start, date_end);

            await this.allRequests();
        },

        // async fixStatisticsDates() {
        //     const date_start = new Date();
        //     const date_end = new Date();
        //     date_start.setDate(date_end.getDate() - 30);
        //
        //     this.date_start = this.formatDate(date_start);
        //     this.date_end = this.formatDate(date_end);
        //
        //     await this.getQuestionnaireStatistics();
        // },

        // SUBSCRIBERS DAILY
        async getSubscribers() {
            this.subscriber.completed = false;
            this.subscriber.xaxis.length = 0;
            this.uniqueDatesSet = new Set();
            this.subscriber.series[0].data.length = 0;
            this.subscriber.series[1].data.length = 0;
            if (parseInt(MERCHANT_ID) === 1) {
                this.subscriber.series[2].data.length = 0;
            }
            // this.subscriber.series[2].data.length = 0;
            try {
                const { data } = await api.homeUsersStatistics.fetchDailyApp({
                    merchant_id: parseInt(MERCHANT_ID),
                    date_start: this.first_day,
                    date_end: this.date_end
                });

                // CREATE A NEW ARRAY WITHOUT DUPLICATED DATES FROM RESPONSE
                for (const item of data.data) {
                    if (item.app === 'app') {
                        this.subscriber.series[0].data.push(parseInt(item['verified_count']));
                    } else if (item.app === 'telegram') {
                        this.subscriber.series[1].data.push(parseInt(item['verified_count']));
                    } else {
                        this.subscriber.series[2].data.push(parseInt(item['verified_count']));
                    }
                    if (!this.uniqueDatesSet.has(item.date)) {
                        this.uniqueDatesSet.add(item.date);
                        this.subscriber.xaxis.push(item.date);
                    }
                }
            } catch (e) {
                console.error(e);
            }
        },

        // SUBSCRIBERS DAILY OVERALL
        async getSubscribersDailyOverall() {
            this.datesForCategories.length = 0;
            this.subscribersCount.completed = false;
            this.subscribersCount.series[0].data.length = 0;
            try {
                const { data } = await api.homeUsersStatistics.fetchDaily({
                    merchant_id: parseInt(MERCHANT_ID),
                    date_start: this.date_start,
                    date_end: this.date_end
                });
                this.datesForCategories = data.data.map((item) => {
                    return item.date.substring(5);
                });
                for (const item of data.data) {
                    this.subscribersCount.series[0].data.push(parseInt(item['verified_count']));
                }
            } catch (e) {
                console.error(e);
            } finally {
                this.subscribersCount.completed = true;
            }
        },

        // SUBSCRIBERS TOTAL
        async getTotalSubscribers() {
            this.verificationPieChart.completed = false;
            this.activeUsersPieChart.completed = false;
            this.verificationPieChart.series.length = 0;
            this.verificationPieChart.labels.length = 0;
            this.activeUsersPieChart.series.length = 0;
            this.activeUsersPieChart.labels.length = 0;
            this.verificationPieChart.verified = null;
            this.verificationPieChart.unverified = null;
            this.activeUsersPieChart.active = null;
            this.activeUsersPieChart.inactive = null;
            try {
                const { data } = await api.homeUsersStatistics.fetchTotal({
                    merchant_id: MERCHANT_ID,
                    date_start: this.first_day,
                    date_end: this.date_end
                });
                this.subscriber.total = data.data['verified_count'];
                this.verificationPieChart.series.push(
                    parseInt(data.data['verified_count']),
                    parseInt(data.data['unverified_count'])
                );
                this.verificationPieChart.labels.push('Verified', 'Unverified');
                this.verificationPieChart.verified = data.data['verified_count'];
                this.verificationPieChart.unverified = data.data['unverified_count'];
                this.activeUsersPieChart.series.push(
                    parseInt(data.data['active_count']),
                    parseInt(data.data['inactive_count'])
                );
                this.activeUsersPieChart.active = data.data['active_count'];
                this.activeUsersPieChart.inactive = data.data['inactive_count'];
                this.activeUsersPieChart.labels.push('Active', 'Inactive');
            } catch (e) {
                console.error(e);
            } finally {
                this.verificationPieChart.completed = true;
                this.activeUsersPieChart.completed = true;
                this.subscriber.completed = true;
            }
        },

        // GIFTS DAILY AND TOTAL
        // PROFIT EXPENSES (DAILY AND TOTAL)
        async getGifts() {
            this.gifts.completed = false;
            this.profitExpenses.completed = false;
            this.gifts.series[0].data.length = 0;
            this.gifts.xaxis.length = 0;
            this.profitExpenses.series[0].data.length = 0;
            this.profitExpenses.xaxis.length = 0;
            this.profitExpenses.total = null;
            this.gifts.total = null;
            try {
                const totalData = await api.homePackagesStatistics.fetchTotal({
                    merchant_id: parseInt(MERCHANT_ID),
                    date_start: this.first_day,
                    date_end: this.date_end
                });
                const { data } = await api.homePackagesStatistics.fetchDaily({
                    merchant_id: MERCHANT_ID,
                    date_start: this.first_day,
                    date_end: this.date_end
                });
                this.profitExpenses.total = totalData.data.data.sum;
                this.gifts.total = totalData.data.data.count;
                data.data.forEach((item) => {
                    this.gifts.series[0].data.push(parseInt(item.count));
                    // this.gifts.total += parseInt(item.count);
                    this.gifts.xaxis.push(item.date);

                    this.profitExpenses.series[0].data.push(parseInt(item.sum));
                    // this.profitExpenses.total += parseInt(item.sum);
                    this.profitExpenses.xaxis.push(item.date);
                });
            } catch (e) {
                console.error(e);
            } finally {
                this.gifts.completed = true;
                this.profitExpenses.completed = true;
            }
        },

        // INVOICE DAILY AND TOTAL
        // PROFIT TOTAL (DAILY AND TOTAL)
        async getInvoices() {
            this.invoice.completed = false;
            this.profitTotal.completed = false;
            this.invoice.series[0].data.length = 0;
            this.invoice.xaxis.length = 0;
            this.profitTotal.series[0].data.length = 0;
            this.profitTotal.xaxis.length = 0;
            try {
                // homeChargesStatistics.fetchTotal
                const totalData = await api.homeChargesStatistics.fetchTotal({
                    merchant_id: parseInt(MERCHANT_ID),
                    date_start: this.first_day,
                    date_end: this.date_end
                });
                const { data } = await api.homeChargesStatistics.fetchDaily({
                    merchant_id: MERCHANT_ID,
                    date_start: this.first_day,
                    date_end: this.date_end
                });
                this.profitTotal.total = totalData.data.data.sum;
                this.invoice.total = totalData.data.data.count;
                data.data.forEach((item) => {
                    this.invoice.series[0].data.push(parseInt(item.count));
                    // this.invoice.total += parseInt(item.count);
                    this.invoice.xaxis.push(item.date);

                    this.profitTotal.series[0].data.push(parseInt(item.sum));
                    // this.profitTotal.total += parseInt(item.sum);
                    this.profitTotal.xaxis.push(item.date);
                });
            } catch (e) {
                console.error(e);
            } finally {
                this.invoice.completed = true;
                this.profitTotal.completed = true;
            }
        },

        // PROFIT REVENUE
        async getProfitRevenue() {
            this.profitRevenue.completed = false;
            this.profitRevenue.series[0].data.length = 0;
            this.profitRevenue.xaxis.length = 0;
            try {
                const { data } = await api.homeInvoiceStatistics.fetchRevenue({
                    merchant_id: MERCHANT_ID,
                    date_start: this.first_day,
                    date_end: this.date_end
                });
                data.data.dates.forEach((item) => {
                    this.profitRevenue.series[0].data.push(parseInt(item.sum));
                    this.profitRevenue.xaxis.push(item.date);
                });
                this.profitRevenue.total = data.data.total.sum;
            } catch (e) {
                console.error(e);
            } finally {
                this.profitRevenue.completed = true;
            }
        },

        // CHARGES (DAILY AND PREMIUM)
        async getChargesByProduct() {
            this.charges.daily_1.completed = false;
            this.charges.isFetched = false;
            this.defaultXaxis.length = 0;
            this.charges.daily_1.series[0].data.length = 0;
            this.charges.daily_1.labels.length = 0;
            this.charges.daily_2.series[0].data.length = 0;
            this.charges.daily_2.labels.length = 0;
            this.charges.daily_3.series[0].data.length = 0;
            this.charges.daily_3.labels.length = 0;
            this.charges.premium_1.series[0].data.length = 0;
            this.charges.premium_1.labels.length = 0;
            this.charges.premium_2.series[0].data.length = 0;
            this.charges.premium_2.labels.length = 0;
            this.charges.premium_3.series[0].data.length = 0;
            this.charges.premium_3.labels.length = 0;
            try {
                const { data } = await api.homeChargesStatistics.fetchDailyProduct({
                    ...this.defaultBody,
                    merchant_id: parseInt(MERCHANT_ID),
                    date_start: this.date_start,
                    date_end: ''
                });

                for (const item of data.data) {
                    if (parseInt(MERCHANT_ID) === 2) {
                        if (item.product === 'subscription-daily') {
                            this.charges.daily_1.series[0].data.push(item.count);
                            this.charges.daily_1.labels.push(item.sum);
                        } else if (item.product === 'daily-2') {
                            this.charges.daily_2.series[0].data.push(item.count);
                            this.charges.daily_2.labels.push(item.sum);
                        } else if (item.product === 'daily-3') {
                            this.charges.daily_3.series[0].data.push(item.count);
                            this.charges.daily_3.labels.push(item.sum);
                        } else if (item.product === 'premium-1') {
                            this.charges.premium_1.series[0].data.push(item.count);
                            this.charges.premium_1.labels.push(item.sum);
                        } else if (item.product === 'premium-2') {
                            this.charges.premium_2.series[0].data.push(item.count);
                            this.charges.premium_2.labels.push(item.sum);
                        } else if (item.product === 'premium-3') {
                            this.charges.premium_3.series[0].data.push(item.count);
                            this.charges.premium_3.labels.push(item.sum);
                        }
                    } else {
                        if (item.product === 'daily-1') {
                            this.charges.daily_1.series[0].data.push(item.count);
                            this.charges.daily_1.labels.push(item.sum);
                        } else if (item.product === 'daily-2') {
                            this.charges.daily_2.series[0].data.push(item.count);
                            this.charges.daily_2.labels.push(item.sum);
                        } else if (item.product === 'daily-3') {
                            this.charges.daily_3.series[0].data.push(item.count);
                            this.charges.daily_3.labels.push(item.sum);
                        } else if (item.product === 'premium-1') {
                            this.charges.premium_1.series[0].data.push(item.count);
                            this.charges.premium_1.labels.push(item.sum);
                        } else if (item.product === 'premium-2') {
                            this.charges.premium_2.series[0].data.push(item.count);
                            this.charges.premium_2.labels.push(item.sum);
                        } else if (item.product === 'premium-3') {
                            this.charges.premium_3.series[0].data.push(item.count);
                            this.charges.premium_3.labels.push(item.sum);
                        }
                    }
                }

                this.subscriber.xaxis.forEach((item) => {
                    // eslint-disable-next-line no-unused-vars
                    const [y, d, m] = item.split('-');
                    this.defaultXaxis.push(`${m}.${d}`);
                });
            } catch (e) {
                console.error(e);
            } finally {
                this.charges.isFetched = true;
                this.charges.daily_1.completed = true;
            }
        },

        //PAYNET BALANCE
        async getPaynetBalance() {
            try {
                const { data } = await api.paynetApiV2.fetchBalance({
                    merchant_id: parseInt(MERCHANT_ID),
                    date_start: this.date_start,
                    date_end: this.date_end
                });
                this.paynetBalance = data.data.balance;
            } catch (e) {
                console.error(e);
            }
        },

        // REGISTRATION STATISTICS
        async getRegistrationStatistics() {
            this.registration.isLoaded = false;
            this.registration.categories.length = 0;
            this.registration.series.length = 0;
            try {
                const { data } = await api.coin.fetchRegistrationStatistics({
                    merchant_id: parseInt(MERCHANT_ID),
                    date_start: this.date_start,
                    date_end: this.date_end
                });
                const response = data.data;
                this.registration.categories = response.map((item) => {
                    return item['recorded_at'].substring(5);
                });

                const keys = new Set();
                response.forEach((obj) => {
                    Object.keys(obj).forEach((key) => {
                        if (key !== 'recorded_at') {
                            keys.add(key);
                        }
                    });
                });

                this.registration.series = Array.from(keys).map((key) => {
                    const data = response.map((obj) => obj[key] || 0);
                    return { name: key, data };
                });
            } catch (e) {
                console.error(e);
            } finally {
                this.registration.isLoaded = true;
            }
        },

        // INVITATION STATISTICS
        async getInvitationStatistics() {
            this.invitationStatistics.categories.length = 0;
            this.invitationStatistics.series.length = 0;
            try {
                this.invitationStatistics.isPending = true;
                const { data } = await api.referralPrizeApi.fetchInvitationStatistics({
                    merchant_id: parseInt(MERCHANT_ID),
                    date_start: this.referralDateStart,
                    date_end: this.referralDateEnd
                });
                this.referralTotal = data.data.total.count;
                this.invitationStatistics.categories = data.data.dates.map((item) =>
                    item.date.substring(5)
                );
                this.invitationStatistics.series = data.data.dates[0].list.map((item) => ({
                    name: item.service,
                    data: [item.count]
                }));
                data.data.dates.slice(1).forEach((day) => {
                    day.list.forEach((item, i) => {
                        this.invitationStatistics.series[i].data.push(item.count);
                    });
                });
            } catch (e) {
                console.error(e);
            } finally {
                this.invitationStatistics.isPending = false;
            }
        },

        // SUBSCRIPTION EVENT STATISTICS
        async getSubscriptionEventStatistics() {
            this.subscriptionEventStatistics.activationSeries.length = 0;
            this.subscriptionEventStatistics.deactivationSeries.length = 0;
            this.subscriptionEventStatistics.categories.length = 0;
            this.subscriptionEventStatistics.isPending = true;
            try {
                const { data } = await api.otherStatistics.fetchSubscriptionEventStatistics({
                    date_start: this.date_start,
                    date_end: this.date_end,
                    merchant_id: parseInt(MERCHANT_ID)
                });

                this.subscriptionEventStatistics.categories = data.data.dates.map((item) =>
                    item.date.substring(5)
                );

                const services = [
                    ...new Set(
                        data.data.dates.flatMap((item) =>
                            item.list.map((service) => service.service)
                        )
                    )
                ];

                this.subscriptionEventStatistics.activationSeries = services.map((service) => ({
                    name: service,
                    data: data.data.dates.map((item) => {
                        const serviceData = item.list.find((entry) => entry.service === service);
                        return serviceData ? serviceData['activation_count'] : 0;
                    })
                }));

                this.subscriptionEventStatistics.deactivationSeries = services.map((service) => ({
                    name: service,
                    data: data.data.dates.map((item) => {
                        const serviceData = item.list.find((entry) => entry.service === service);
                        return serviceData ? serviceData['deactivation_count'] : 0;
                    })
                }));
            } catch (e) {
                console.error(e);
            } finally {
                this.subscriptionEventStatistics.isPending = false;
            }
        },

        async getNewUserUssdStatistics() {
            this.newUserUssd.isLoaded = false;
            this.newUserUssd.categories.length = 0;
            this.newUserUssd.series.length = 0;
            try {
                const { data } = await api.otherStatistics.fetchNewUserUssdStatistics({
                    merchant_id: parseInt(MERCHANT_ID),
                    date_start: this.date_start,
                    date_end: this.date_end
                });

                this.newUserUssd.categories = data.data.dates.map((item) => item.date.substring(5));

                const items = {};

                data.data.dates.forEach((item) => {
                    item.list.forEach((date) => {
                        const itemName = checkLocales(date.name);
                        if (!items[itemName]) {
                            items[itemName] = new Array(data.data.dates.length).fill(0);
                        }
                        const index = data.data.dates.findIndex((obj) => obj.date === item.date);
                        items[itemName][index] = date.count;
                    });
                });

                this.newUserUssd.series = Object.entries(items).map(([name, data]) => ({
                    name,
                    data
                }));
            } catch (e) {
                console.error(e);
            } finally {
                this.newUserUssd.isLoaded = true;
            }
        },

        async allRequests() {
            // SUBSCRIBERS
            await this.getSubscribers();
            await this.getTotalSubscribers();

            // GIFTS
            await this.getGifts();

            // INVOICE
            await this.getInvoices();

            // PROFIT REVENUE
            await this.getProfitRevenue();

            // PAYNET BALANCE
            await this.getPaynetBalance();

            // REGISTRATION
            await this.getRegistrationStatistics();

            // USSD STATISTICS
            // await this.getUssdStatistics();

            // USSD NEW USER STATISTICS
            // await this.getNewUserUssdStatistics();

            // CHARGES (DAILY, PREMIUM)
            await this.getSubscribersDailyOverall();
            await this.getChargesByProduct();

            // INVITATION STATISTICS
            await this.getInvitationStatistics();

            // SUBSCRIPTION EVENT
            await this.getSubscriptionEventStatistics();
        },

        async filterRequests() {
            await this.getSubscribers();
            await this.getTotalSubscribers();

            // GIFTS
            await this.getGifts();

            // INVOICE
            await this.getInvoices();

            // PROFIT REVENUE
            await this.getProfitRevenue();
        }
    }
};
</script>

<template>
    <div class="home">
        <div class="row align-items-end mb-2">
            <div class="col-12 col-md-10 d-flex flex-wrap align-items-center">
                <div class="col-12 col-sm-6">
                    <label for="start_date">{{ $t('titles.date_start_at') }}</label>
                    <flat-pickr
                        v-model="first_day"
                        :config="{
                            allowInput: true,
                            time_24hr: true
                        }"
                        :placeholder="$t('choose.date')"
                        class="form-control"
                        name="date"
                        id="start_date"
                    />
                </div>
                <div class="col-12 col-sm-6 mt-1 mt-sm-0">
                    <label for="end_date">{{ $t('titles.date_end_at') }}</label>
                    <flat-pickr
                        v-model="date_end"
                        :config="{
                            allowInput: true,
                            time_24hr: true
                        }"
                        :placeholder="$t('choose.date')"
                        class="form-control"
                        name="date"
                        id="end_date"
                    />
                </div>
            </div>
            <div class="col-12 col-md-2 px-2">
                <b-button @click="filterRequests" class="w-100 mt-1 mt-md-0" variant="primary">
                    <feather-icon icon="SearchIcon" />
                </b-button>
            </div>
        </div>
        <div class="cards__grid">
            <!--      SUBSCRIBERS      -->
            <div class="card d-flex flex-column">
                <progress-linear v-if="!subscriber.completed" />
                <AppHomeCardWithChart
                    v-else
                    icon="UsersIcon"
                    icon-size="24"
                    icon-color="light-primary"
                    total-text="titles.users"
                    :total="subscriber.total"
                    :chart-line-colors="subscriber.chartLineColors"
                    :series="subscriber.series"
                    :dynamic-xaxis="subscriber.xaxis"
                    :loaded="subscriber.completed"
                />
            </div>

            <!--      GIFTS      -->
            <div class="card d-flex flex-column">
                <progress-linear v-if="!gifts.completed" />
                <AppHomeCardWithChart
                    v-else
                    icon="GiftIcon"
                    icon-size="24"
                    icon-color="light-primary"
                    total-text="titles.gifts"
                    :total="gifts.total"
                    :chart-line-colors="gifts.chartLineColors"
                    :series="gifts.series"
                    :dynamic-xaxis="gifts.xaxis"
                    :loaded="gifts.completed"
                />
            </div>

            <!--      INVOICE      -->
            <div class="card d-flex flex-column">
                <progress-linear v-if="!invoice.completed" />
                <AppHomeCardWithChart
                    v-else
                    icon="FileTextIcon"
                    icon-size="24"
                    icon-color="light-primary"
                    total-text="Invoices"
                    :total="invoice.total"
                    :chart-line-colors="invoice.chartLineColors"
                    :series="invoice.series"
                    :dynamic-xaxis="invoice.xaxis"
                    :loaded="invoice.completed"
                />
            </div>

            <!--      SUBSCRIBERS VERIFICATIONS      -->
            <div class="card d-flex justify-content-between align-items-center">
                <h3 v-if="verificationPieChart.completed" class="subscribers__title">
                    Subscribers
                </h3>
                <div
                    v-if="verificationPieChart.completed"
                    class="w-100 d-flex align-items-center justify-content-around"
                >
                    <p class="mb-0">Verified: {{ formatNumber(verificationPieChart.verified) }}</p>
                    <p class="mb-0">
                        Unverified: {{ formatNumber(verificationPieChart.unverified) }}
                    </p>
                </div>
                <progress-linear v-if="!verificationPieChart.completed" />
                <apexchart
                    v-else
                    width="220"
                    :options="verificationPieChart"
                    :series="verificationPieChart.series"
                />
            </div>

            <!--      PROFIT TOTAL      -->
            <div class="card d-flex flex-column">
                <progress-linear v-if="!profitTotal.completed" />
                <AppHomeCardWithChart
                    v-else
                    icon="GiftIcon"
                    icon-size="24"
                    icon-color="light-primary"
                    total-text="Profit Total"
                    :total="profitTotal.total"
                    :chart-line-colors="profitTotal.chartLineColors"
                    :series="profitTotal.series"
                    :dynamic-xaxis="profitTotal.xaxis"
                    :loaded="profitTotal.completed"
                />
            </div>

            <!--      PROFIT EXPENSES      -->
            <div class="card d-flex flex-column">
                <progress-linear v-if="!profitExpenses.completed" />
                <AppHomeCardWithChart
                    v-else
                    icon="GiftIcon"
                    icon-size="24"
                    icon-color="light-primary"
                    total-text="Profit Expenses"
                    :total="profitExpenses.total"
                    :chart-line-colors="profitExpenses.chartLineColors"
                    :series="profitExpenses.series"
                    :dynamic-xaxis="profitExpenses.xaxis"
                    :loaded="profitExpenses.completed"
                />
            </div>

            <!--      PROFIT REVENUE      -->
            <div class="card d-flex flex-column">
                <progress-linear v-if="!profitRevenue.completed" />
                <AppHomeCardWithChart
                    v-else
                    icon="GiftIcon"
                    icon-size="24"
                    icon-color="light-primary"
                    total-text="Profit Revenue"
                    :total="profitRevenue.total"
                    :chart-line-colors="profitRevenue.chartLineColors"
                    :series="profitRevenue.series"
                    :dynamic-xaxis="profitRevenue.xaxis"
                    :loaded="profitRevenue.completed"
                />
            </div>

            <!--      SUBSCRIBERS ACTIVE RATE      -->
            <div class="card d-flex justify-content-between align-items-center">
                <h3 v-if="activeUsersPieChart.completed" class="subscribers__title">Subscribers</h3>
                <div
                    v-if="activeUsersPieChart.completed"
                    class="w-100 d-flex align-items-center justify-content-around"
                >
                    <p class="mb-0">Active: {{ formatNumber(activeUsersPieChart.active) }}</p>
                    <p class="mb-0">Inactive: {{ formatNumber(activeUsersPieChart.inactive) }}</p>
                </div>
                <progress-linear v-if="!activeUsersPieChart.completed" />
                <apexchart
                    v-else
                    width="220"
                    :options="activeUsersPieChart"
                    :series="activeUsersPieChart.series"
                />
            </div>
        </div>

        <div class="row">
            <!--      SUBSCRIBERS OVERALL COUNT (TELEGRAM, APP, USSD)      -->
            <div class="col-12 col-lg-10">
                <div class="card">
                    <progress-linear v-if="!subscribersCount.completed" />
                    <AppHomeLongChart
                        v-else
                        :chart-bar-colors="subscribersCount.chartBarColors"
                        :title="$t('titles.users')"
                        :series="subscribersCount.series"
                        :loaded="false"
                        :dynamic-xaxis="datesForCategories"
                        :labels="subscribersCount.labels"
                        :enable-tooltip="false"
                    />
                </div>
            </div>

            <div class="col-12 col-lg-2">
                <progress-linear v-if="!paynetBalance" />
                <div v-else class="card p-1 text-center">
                    <h4>PAYNET {{ $t('titles.balance') }}</h4>
                    <h4>{{ formatToPrice(paynetBalance) }}</h4>
                </div>
            </div>

            <!--      REGISTRATION STATISTICS      -->
            <div class="col-12">
                <div class="card">
                    <!--    <AppRegistrationStatistics />    -->
                    <progress-linear v-if="invitationStatistics.isPending" />
                    <CompStackedChart
                        v-else
                        :title="$t('titles.registration_statistics')"
                        :is-pending="!registration.isLoaded"
                        :series="registration.series"
                        :categories="registration.categories"
                    />
                </div>
            </div>

            <!--      USSD NEW USER STATISTICS      -->
            <!--            <div class="col-12">-->
            <!--                <div class="card">-->
            <!--                    <progress-linear v-if="!newUserUssd.isLoaded" />-->
            <!--                    <CompStackedChart-->
            <!--                        v-else-->
            <!--                        :title="$t('titles.new_user_ussd_statistics')"-->
            <!--                        :is-pending="!newUserUssd.isLoaded"-->
            <!--                        :series="newUserUssd.series"-->
            <!--                        :categories="newUserUssd.categories"-->
            <!--                    />-->
            <!--                </div>-->
            <!--            </div>-->

            <!--      DAILY SUBSCRIPTION      -->
            <div class="col-12" v-if="parseInt(MERCHANT_ID) === 2">
                <div class="card">
                    <progress-linear v-if="!charges.isFetched" />
                    <AppHomeLongChart
                        v-else
                        :chart-bar-colors="charges.daily_1.chartBarColors"
                        :title="$t('bonus_statistics.daily_subscription')"
                        :series="charges.daily_1.series"
                        :loaded="false"
                        :dynamic-xaxis="datesForCategories"
                        :labels="charges.daily_1.labels"
                    />
                </div>
            </div>

            <!--      DAILY 2      -->
            <div class="col-12">
                <div class="card">
                    <progress-linear v-if="!charges.isFetched" />
                    <AppHomeLongChart
                        v-else
                        :chart-bar-colors="charges.daily_2.chartBarColors"
                        :title="$t('bonus_statistics.daily_two')"
                        :series="charges.daily_2.series"
                        :loaded="false"
                        :dynamic-xaxis="datesForCategories"
                        :labels="charges.daily_2.labels"
                    />
                </div>
            </div>

            <!--      DAILY 3      -->
            <div class="col-12">
                <div class="card">
                    <progress-linear v-if="!charges.isFetched" />
                    <AppHomeLongChart
                        v-else
                        :chart-bar-colors="charges.daily_3.chartBarColors"
                        :title="$t('bonus_statistics.daily_three')"
                        :series="charges.daily_3.series"
                        :loaded="false"
                        :dynamic-xaxis="datesForCategories"
                        :labels="charges.daily_3.labels"
                    />
                </div>
            </div>

            <!--      PREMIUM 1      -->
            <div class="col-12">
                <div class="card">
                    <progress-linear v-if="!charges.isFetched" />
                    <AppHomeLongChart
                        v-else
                        :chart-bar-colors="charges.premium_1.chartBarColors"
                        :title="$t('bonus_statistics.premium_one')"
                        :series="charges.premium_1.series"
                        :loaded="false"
                        :dynamic-xaxis="datesForCategories"
                        :labels="charges.premium_1.labels"
                    />
                </div>
            </div>

            <!--      PREMIUM 2      -->
            <div class="col-12">
                <div class="card">
                    <progress-linear v-if="!charges.isFetched" />
                    <AppHomeLongChart
                        v-else
                        :chart-bar-colors="charges.premium_2.chartBarColors"
                        :title="$t('bonus_statistics.premium_two')"
                        :series="charges.premium_2.series"
                        :loaded="false"
                        :dynamic-xaxis="datesForCategories"
                        :labels="charges.premium_2.labels"
                    />
                </div>
            </div>

            <!--      PREMIUM 3      -->
            <div class="col-12">
                <div class="card">
                    <progress-linear v-if="!charges.isFetched" />
                    <AppHomeLongChart
                        v-else
                        :chart-bar-colors="charges.premium_3.chartBarColors"
                        :title="$t('bonus_statistics.premium_three')"
                        :series="charges.premium_3.series"
                        :loaded="false"
                        :dynamic-xaxis="datesForCategories"
                        :labels="charges.premium_3.labels"
                    />
                </div>
            </div>

            <!--      INVITATION STATISTICS      -->
            <div class="col-12">
                <div class="card">
                    <progress-linear v-if="invitationStatistics.isPending" />
                    <CompStackedChart
                        v-else
                        :total="referralTotal"
                        :is-pending="invitationStatistics.isPending"
                        :series="invitationStatistics.series"
                        :categories="invitationStatistics.categories"
                        :title="$t('titles.invitation_statistics')"
                    >
                        <template #datePicker>
                            <div class="d-flex flex-column flex-md-row align-items-md-end">
                                <div class="mb-1 mb-md-0 mr-lg-1">
                                    <label for="start_date">{{ $t('titles.date_start_at') }}</label>
                                    <flat-pickr
                                        v-model="referralDateStart"
                                        :config="{
                                            allowInput: true,
                                            time_24hr: true
                                        }"
                                        :placeholder="$t('choose.date')"
                                        class="form-control"
                                        name="date"
                                        id="start_date"
                                    />
                                </div>
                                <div class="mb-1 mb-md-0">
                                    <label for="end_date">{{ $t('titles.date_end_at') }}</label>
                                    <flat-pickr
                                        v-model="referralDateEnd"
                                        :config="{
                                            allowInput: true,
                                            time_24hr: true
                                        }"
                                        :placeholder="$t('choose.date')"
                                        class="form-control"
                                        name="date"
                                        id="end_date"
                                    />
                                </div>
                                <b-button
                                    @click="getInvitationStatistics"
                                    variant="outline-primary"
                                    class="ml-0 ml-sm-1"
                                    style="height: max-content"
                                >
                                    <feather-icon icon="SearchIcon" size="14" />
                                </b-button>
                            </div>
                        </template>
                    </CompStackedChart>
                </div>
            </div>

            <!--      SUBSCRIPTION EVENT [ACTIVATION]      -->
            <div class="col-12">
                <div class="card">
                    <progress-linear v-if="subscriptionEventStatistics.isPending" />
                    <CompStackedChart
                        v-else
                        :is-pending="subscriptionEventStatistics.isPending"
                        :series="subscriptionEventStatistics.activationSeries"
                        :categories="subscriptionEventStatistics.categories"
                        :title="`${$t('titles.subscription_event_statistics')} [${$t(
                            'titles.activation'
                        )}]`"
                    />
                </div>
            </div>

            <!--      SUBSCRIPTION EVENT [DEACTIVATION]      -->
            <div class="col-12">
                <div class="card">
                    <progress-linear v-if="subscriptionEventStatistics.isPending" />
                    <CompStackedChart
                        v-else
                        :is-pending="subscriptionEventStatistics.isPending"
                        :series="subscriptionEventStatistics.deactivationSeries"
                        :categories="subscriptionEventStatistics.categories"
                        :title="`${$t('titles.subscription_event_statistics')} [${$t(
                            'titles.deactivation'
                        )}]`"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.cards__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 1.5rem;
    grid-row-gap: 0;
}

@media only screen and (max-width: 1550px) {
    .cards__grid {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 1300px) {
    .cards__grid {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 700px) {
    .cards__grid {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(2, 1fr);
    }
}
</style>
